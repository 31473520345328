html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#root {
    height: 100vh;
}

.overlay {
    position: absolute;
    left: 0%;
    top: 0%;
    height: 100%;
    width: 100%;
}